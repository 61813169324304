<template>
  <div class="school_line">
    <div class="search_item">
      <el-row>
        <el-col :span="24">
          <span class="school_name" v-for="(item, i) in NavList" :class="{ hove: nav == i }" :key="i"
            @click="changeCollege(item, i)">{{ item.school_id }} &nbsp;{{ item.school_name }}</span>
        </el-col>
        <el-col :span="3" style="margin-top:10px">
          <el-select v-model="batch" style="width:120px" size="mini" class="round_select" placeholder="请选择"
            @change="onChange">
            <el-option v-for="item in batchList" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-col>
      </el-row>
    </div>
    <el-table v-loading='loading' :data="scoreLineList" style="width: 100% ;font-size:16px;text-align:center" border
      :header-cell-style="{
          'background-color': '#f4f7fc',
          'color': 'black',
          'letter-spacing': '1px', padding: '6px 0', 'text-align': 'center', 'text-indent': '0'
        }" :cell-style="{ height: '10px', padding: '4px 0' }" align="center">
      <el-table-column prop="year" label="年份" align="center">
      </el-table-column>
      <el-table-column prop="is_wenli" align="center" label="科类">
        <template slot-scope="scope">
          {{ scope.row.is_wenli == 1 ? '文科' : '理科' }}
        </template>
      </el-table-column>
      <el-table-column prop="batch" align="center" label="批次">
        <template slot-scope="scope">
          <p>
            <span v-if="scope.row.batch != 4">{{ batchList[scope.row.batch - 1].name }}</span>
            <span v-if="scope.row.batch == 4">{{ scope.row.pici }}</span>
          </p>
        </template>
      </el-table-column>
      <el-table-column prop="max_score" align="center" label="最高分" />
      <el-table-column prop="max_ranking" align="center" label="最高位次" />
      <el-table-column prop="mean_score" class-name='text-center' label="平均分" />
      <el-table-column prop="min_score" align="center" width="135px" label="最低分">
        <template slot-scope="scope">
          <p>
            <span>{{ scope.row.min_score }}</span>
            <span v-if="scope.row.min_score != scope.row.td_score && scope.row.td_score > 0" style="color:#ff6600">/投档{{
          scope.row.td_score
        }}</span>
          </p>
        </template>
      </el-table-column>
      <el-table-column prop="difference" align="center" label="线差" />
      <el-table-column prop="ranking" align="center" label="最低位次" />
      <el-table-column prop="plan_num" align="center" label="计划数" />
      <el-table-column prop="matriculate" align="center" label="录取数" />
    </el-table>

    <div class="search_item" v-if="batch != 4">
      <span class="title">数据分析表</span>
    </div>

    <!-- 图形 -->
    <div class="ECharts" v-if="batch != 4">
      <div id="chart" :style="{
          width: '1200px',
          height: '300px',
          border: '1px solid #f4f4f4',
        }" />
      <div id="myChart" :style="{
          width: '1200px',
          height: '300px',
          margin: '50px 0 50px 0',
          border: '1px solid #f4f4f4',
        }"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

//time: 2020/12/15
export default {
  name: "collegeScoreLine",
  data() {
    return {
      batch: "1",
      is_wenli: 1,
      scoreLineList: [],
      batchList: [
        { id: "1", name: "本科一批", code: "1", status: "1" },
        { id: "2", name: "本科二批", code: "2", status: "2" },
        { id: "3", name: "高职高专批", code: "3", status: "3" },
        { id: "4", name: "其他批次", code: "4", status: "4" },

      ],
      pageNum: 1,
      pageSize: 15,
      nav: 0,
      NavList: [],
      select_code: "",
      loading: false,
    };
  },
  computed: { ...mapState(["userInfo"]) },
  created() {
    //批次设置
    if (this.userInfo) {
      this.is_wenli = this.userInfo.is_wenli;
      this.pageSize = 15;
    } else {
      this.is_wenli = 1;
      this.pageSize = 2;
    }
    this.school_id = this.$route.query.sid;
    this.getNav();
  },
  methods: {
    //获取分类
    getNav() {
      if (!this.school_id) {
        return;
      }
      this.scoreLine = [];
      this.$fecth
        .get("show_school_score/getNav", {
          params: {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            batch: this.batch,
            is_wenli: this.is_wenli,
            school_id: this.school_id,
            child_school_id: this.child_school_id,
            type: 3,
          },
        })
        .then((res) => {
          if (res.length > 0) {
            this.NavList = res;
            if (this.NavList[0]) {
              this.select_code = this.NavList[0].select_code;
              this.batch = String(this.NavList[0].batch);
            }
            this.getData();
          } else {
            this.loading = false;
          }
        });
    },
    //获取院校数据
    getData() {
      this.loading = true;
      if (this.select_code == "") {
        this.loading = false;
        return false;
      }
      this.$fecth
        .get("show_school_score/getData", {
          params: {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            batch: this.batch,
            is_wenli: this.is_wenli,
            select_code: this.select_code,
          },
        })
        .then((res) => {
          this.loading = false;
          this.scoreLineList = res.list;
          //生成柱形图
          if (this.batch != 4) {
            this.toEcharts(res);
          }
        });
    },
    onChange() {
      this.getData();
    },

    changeCollege(item, i) {
      this.nav = i;
      this.select_code = item.select_code;
      this.batch = String(item.batch);
      this.getData();
    },

    toEcharts(res) {
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          data: ["最高分", "平均分", "最低分", "录取人数"],
          top: 20,
          textStyle: {
            fontSize: 12,
          },
          x: "140px",
          borderRadius: [5, 5, 0, 0], //（顺时针左上，右上，右下，左下）
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        grid: {
          top: "17%",
          right: "3%",
          bottom: "3%",
          left: "3%",
          containLabel: true,
          borderWidth: 1,
          borderColor: "red",
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: [],
          },
        ],
        yAxis: [
          {
            name: this.name,
            scale: true,
            type: "value",
          },
        ],
        series: [
          {
            name: "最高分",
            type: "line",
            data: [],
          },
          {
            name: "平均分",
            type: "line",
            data: [],
          },
          {
            name: "最低分",
            type: "line",
            data: [],
          },
        ],
        color: ["#97CA36", "red", "blue", "#826DED"],
      };
      let options = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        grid: {
          top: "16%",
          right: "4%",
          bottom: "3%",
          left: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: [],
          },
        ],
        yAxis: [
          {
            name: "位次",
            type: "value",
          },
        ],
        series: [
          {
            name: "最低位次",
            type: "line",
            data: [],
          },
        ],
        color: ["#97CA36"],
      };
      let ChartData = [];
      res.list.forEach((item) => {
        ChartData.unshift(item);
      });
      for (const item of ChartData) {
        option.xAxis[0].data.push(item.year);
        if (item.max_score == 0) {
          option.series[0].data.push("");
        } else {
          option.series[0].data.push(item.max_score);
        }

        if (item.mean_score == 0) {
          option.series[1].data.push("");
        } else {
          option.series[1].data.push(item.mean_score);
        }

        if (item.min_score == 0) {
          option.series[2].data.push("");
        } else {
          option.series[2].data.push(item.min_score);
        }

        options.xAxis[0].data.push(item.year);
        options.series[0].data.push(item.ranking);
      }
      this.drawChart(option, options);
    },

    drawChart(option, options) {
      //引入echarts
      let echarts = require("echarts");
      let myChart = echarts.init(document.getElementById("chart"));
      myChart.setOption(option);
      const testmyChart = echarts.init(document.getElementById("myChart"));
      testmyChart.setOption(options);
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  margin-right: 40px;
  text-indent: 0.5rem;
  display: inline-block;
}

.hove {
  background: #519deb;
  color: white;
  border: 1px #519deb solid !important;
}

.search_item {
  min-height: 19px;
  position: relative;

  .school_name {
    display: inline-block;
    border: 1px solid #c9c5c540;
    height: 20px;
    line-height: 20px;
    padding: 10px 12px;
    font-size: 16px;
    margin-bottom: 2px;
  }

  margin: 20px 0 7px;
}

.table {
  font-size: 16px;
  padding: 20px 40px;
  border: 1px solid #dfdfdf;
  text-align: center;
  line-height: 30px;

  .header {
    font-weight: 700;
  }

  margin-bottom: 40px;
}
</style>

<style lang="less">
.text-center {
  text-indent: 2rem;
}

.round_select {
  .el-input__inner {
    font-size: 16px;
    border-radius: 30px;
    height: 23px;
    line-height: 23px;
  }

  .el-input--mini .el-input__icon {
    line-height: 23px;
  }
}
</style>